import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Toolbar, Grid } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import CommonFunctions from '../common/CommonFunctions';
import { AppContext, CONSTANT } from '../../AppContext';
import Logo from '../../imgs/logo.png';

const { getLastDayLuxonToDisplayWasteAnalysis } = CommonFunctions;

const HEADER_HEIGHT = 57;
const styles = (theme) => ({
  /**
   * App bar: menu icon, title logo, notifications
   */
  root: {
    flexGrow: 1,
    backgroundImage: theme.palette.primaryGradient,
    height: '100%',
  },
  appBar: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      backgroundImage: theme.palette.primaryGradient,
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      backgroundImage: theme.palette.primaryGradient,
      paddingLeft: theme.main.paddingLeftRight,
      paddingRight: theme.main.paddingLeftRight,
    },
  },
  toolBar: {
    // Force height of app bar to be exactly {HEADER_HEIGHT}px
    minHeight: `${HEADER_HEIGHT}px`,
    maxHeight: `${HEADER_HEIGHT}px`,
  },
  logoStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxHeight: '45px',
      verticalAlign: 'middle',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      maxHeight: '45px',
      position: 'absolute',
      top: '-22px',
      left: '-59px',
    },
  },
  logoItem: {
    position: 'relative',
  },
});

const CallbackPage = ({ classes, history }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const context = useContext(AppContext);

  useEffect(() => {
    const { renderLoaderAnimation } = context;
    renderLoaderAnimation(true);
  }, []);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const {
        openSnackbar,
        setGroupBy,
        setStartDate,
        setEndDate,
        setLastDayLuxonToDisplayWasteAnalysis,
      } = context;
      setGroupBy(CONSTANT.groupByDay);

      const lastDayLuxonToDisplayWasteAnalysis = getLastDayLuxonToDisplayWasteAnalysis();
      let selectedStartDateLuxon;
      let selectedEndDateLuxon;
      // The first day will be 1 week before the last day to display waste analysis
      if (process.env.REACT_APP_WEEKDAY && process.env.REACT_APP_TIME_ZONE) {
        selectedStartDateLuxon = lastDayLuxonToDisplayWasteAnalysis.minus({
          days: 6,
        });
        selectedEndDateLuxon = lastDayLuxonToDisplayWasteAnalysis;
      } else {
        selectedStartDateLuxon = lastDayLuxonToDisplayWasteAnalysis.minus({
          days: 7,
        });
        selectedEndDateLuxon = lastDayLuxonToDisplayWasteAnalysis.minus({
          days: 1,
        });
      }

      setStartDate(selectedStartDateLuxon.toFormat('yyyy-MM-dd'));
      setEndDate(selectedEndDateLuxon.toFormat('yyyy-MM-dd'));
      setLastDayLuxonToDisplayWasteAnalysis(lastDayLuxonToDisplayWasteAnalysis);

      openSnackbar("You've successfully logged in!", 'success');
      if (!user.isAdmin) {
        history.push('/');
      } else {
        history.push('/impersonator');
      }
    }
  }, [isLoading]);

  return (
    <div>
      <Box className={`${classes.root}`}>
        <AppBar position="relative" className={classes.appBar} elevation={0}>
          <Toolbar classes={{ regular: classes.toolBar }}>
            <Grid item className={classes.logoItem}>
              <img src={Logo} className={classes.logoStyle} alt="Lumitics" />
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default withRouter(withTheme(withStyles(styles)(CallbackPage)));
