import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  buttonRowContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  enabledAddButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
    borderRadius: '7px',
    '&:disabled': {
      background: '#ebe8ed',
      borderColor: '#ebe8ed',
    },
  },
  enabledCancelSelectionButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    borderRadius: '7px',
  },
  selectAllText: {
    fontWeight: 700,
  },
});

class FilterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      arrElementSelectedForFiltering: props.arrElementSelectedForFiltering,
    };
  }

  /**
   * If checkbox is checked, select all elements. Else, deselect all elements.
   */
  onChangeCheckBoxToSelectOrDeselectAllElements(event) {
    const { arrAllElementForSelection } = this.props;

    if (event.target.checked) {
      this.setState({
        arrElementSelectedForFiltering: arrAllElementForSelection,
      });
    } else {
      this.setState({
        arrElementSelectedForFiltering: [],
      });
    }
  }

  /**
   * If checkbox is checked, add in the element to the array of selected elements. Else, remove the element from the array of selected elements.
   */
  onChangeCheckboxToSelectOrDeselectElement(element) {
    const { arrElementSelectedForFiltering } = this.state;

    // Deselect
    if (arrElementSelectedForFiltering.includes(element)) {
      const arrUpdatedElementSelectedForFiltering = arrElementSelectedForFiltering.filter(
        (selectedElement) => selectedElement !== element
      );
      this.setState({
        arrElementSelectedForFiltering: arrUpdatedElementSelectedForFiltering,
      });
    }
    // Select
    else {
      this.setState({
        arrElementSelectedForFiltering: arrElementSelectedForFiltering.concat(element),
      });
    }
  }

  render() {
    const { classes, arrAllElementForSelection, onClickCancel, onClickFilter } = this.props;
    const { arrElementSelectedForFiltering } = this.state;

    const isAllElementsSelected =
      arrElementSelectedForFiltering.length === arrAllElementForSelection.length;
    const isSomeElementsSelected =
      arrElementSelectedForFiltering.length > 0 &&
      arrElementSelectedForFiltering.length < arrAllElementForSelection.length;
    return (
      <div>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={1}>
            <Checkbox
              checkedIcon={
                isSomeElementsSelected ? <IndeterminateCheckBoxIcon /> : <CheckBoxIcon />
              }
              onChange={(event) => this.onChangeCheckBoxToSelectOrDeselectAllElements(event)}
              checked={isSomeElementsSelected || isAllElementsSelected}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography className={classes.selectAllText}>Select All</Typography>
          </Grid>
        </Grid>

        {arrAllElementForSelection.map((element) => {
          const isElementSelected = arrElementSelectedForFiltering.includes(element);
          return (
            <Grid container direction="row" alignItems="center" key={element}>
              <Grid item xs={1}>
                <Checkbox
                  onChange={() => this.onChangeCheckboxToSelectOrDeselectElement(element)}
                  checked={isElementSelected}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Typography>{element}</Typography>
              </Grid>
            </Grid>
          );
        })}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.buttonRowContainer}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickCancel}
              className={classes.enabledCancelSelectionButton}
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => onClickFilter(arrElementSelectedForFiltering)}
              className={classes.enabledAddButton}
              disabled={arrElementSelectedForFiltering.length === 0}
            >
              <Typography variant="h6">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(FilterModal);
