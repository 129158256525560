import axios from 'axios';
import { format } from 'date-fns';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import React, { PureComponent } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, ClickAwayListener, Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import FoodItemsBreakdownTable from './FoodItemsBreakdownTable';
import LineChart from '../common/chart/LineChart';
import CommonFunctions from '../common/CommonFunctions';
import DropdownList from '../common/DropdownList';
import ToggleWeightCostButton from '../common/ToggleWeightCostButton';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

const { getPreviousDateRangeString } = CommonFunctions;

/**
 * This is the clickthrough component for the breakdown by food items.
 * It acts as a container for the following:
 * - Location drop-down list
 * - Weight/Cost toggle button
 * - Search bar
 * - Table of food items
 *
 * @prop {Array} locationsList
 */

const styles = (theme) => ({
  spacedTitle: theme.typography.spacedTitle,
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootGridItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCard: {
    ...theme.card.home,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
  rootCardGridTableContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  rootCardGridItemDefault: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridItemTitle: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootCardGridSubtitle: {
    paddingTop: '10px',
  },
  rootCardGridItemChart: {
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
    paddingLeft: '8px',
  },
  downloadButton: {
    ...theme.typography.h6,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '7px',
    color: '#50655b',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#088280',
      color: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: 'white',
      color: theme.palette.gray3,
    },
  },
  rootCardGridItemLineChart: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
    },
  },
});

class FoodItemsBreakdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // If menu item service waste analysis is fetched
      isArrLevelWithMenuItemServiceWasteAnalysisFetched: false,

      // For watchlist
      arrMenuItemRestaurantWasteAnalysis: [],
      arrWatchlistItemMenuItemRestaurantWasteAnalysis: [],

      // Data
      arrLevelWithMenuItemServiceWasteAnalysis: [],
      selectedLevelWithMenuItemServiceWasteAnalysis: {}, // This holds the selectedLevelWithMenuItemServiceWasteAnalysis with all the menu item waste analysis for that level
      arrSelectedMenuItemServiceWasteAnalysis: [], // This holds all the checked rows

      // Selected values
      selectedRestaurantService: null,
      selectedToggleValueForWeightCost: 'weight',
      isTooltipOpened: false,

      // Line Chart
      selectedMenuItemWasteAnalysisForLineChart: {}, // This is the line chart for the checked rows
      topMenuItemWasteAnalysisForLineChart: {}, // This is the line chart for the selected location/service group, or for the filtered rows

      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',
    };
  }

  componentDidMount() {
    document.title = 'Food Items Breakdown | Lumitics | Towards Zero Food Waste';
    const { setPageHistory } = this.context;
    const { arrRestaurantService, match } = this.props;

    let arrLocationServiceToFetchMenuItemWasteAnalysis = [];
    let selectedLocationId = 0;
    let pageHeaderString = '';
    const selectedRestaurantId = parseInt(match.params.restaurantId, 10);
    const selectedRestaurantService = arrRestaurantService.find(
      (restaurantService) => restaurantService.restaurantId === selectedRestaurantId
    );
    if (match.params.locationId) {
      selectedLocationId = parseInt(match.params.locationId, 10);
      const selectedLocationService = selectedRestaurantService.arrLocationService.find(
        (locationService) => locationService.locationId === selectedLocationId
      );
      arrLocationServiceToFetchMenuItemWasteAnalysis = [selectedLocationService];
      pageHeaderString = `${selectedRestaurantService.name} - ${selectedLocationService.name}`;
    } else {
      selectedLocationId = selectedRestaurantService.arrLocationService[0].locationId;
      arrLocationServiceToFetchMenuItemWasteAnalysis = selectedRestaurantService.arrLocationService;
      pageHeaderString = selectedRestaurantService.name;
    }

    this.setState({
      selectedRestaurantService,
    });

    setPageHistory([`${pageHeaderString}: ${CONSTANT.breakdownFoodItemsPage}`]);

    this.setPreviousSelectedStartEndDateAndGroupBy();
    this.fetchArrLocationWithMenuItemServiceWasteAnalysis(
      arrLocationServiceToFetchMenuItemWasteAnalysis
    );
    this.fetchWatchlistItems([selectedRestaurantService]);
  }

  componentDidUpdate() {
    const {
      previousSelectedStartDate,
      previousSelectedEndDate,
      previousSelectedGroupBy,
      selectedRestaurantService,
    } = this.state;
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      this.setPreviousSelectedStartEndDateAndGroupBy();
      this.fetchArrLocationWithMenuItemServiceWasteAnalysis(
        selectedRestaurantService.arrLocationService
      );
      this.fetchWatchlistItems([selectedRestaurantService]);
    }
  }

  /**
   * Change selected location/service group
   */
  onChangeDropdownList(event) {
    const { auth0 } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;
    const { arrLevelWithMenuItemServiceWasteAnalysis } = this.state;
    const { value } = event.target;

    let selectedLevelWithMenuItemServiceWasteAnalysis = null;
    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      selectedLevelWithMenuItemServiceWasteAnalysis = arrLevelWithMenuItemServiceWasteAnalysis.find(
        (locationWithMenuItemServiceWasteAnalysis) =>
          locationWithMenuItemServiceWasteAnalysis.locationId === value
      );
    } else {
      selectedLevelWithMenuItemServiceWasteAnalysis = arrLevelWithMenuItemServiceWasteAnalysis.find(
        (levelWithMenuItemWasteAnalysis) => levelWithMenuItemWasteAnalysis.name === value
      );
    }
    this.setState({
      selectedLevelWithMenuItemServiceWasteAnalysis,
      topMenuItemWasteAnalysisForLineChart:
        selectedLevelWithMenuItemServiceWasteAnalysis.topMenuItemWasteAnalysisForLineChart,
      selectedMenuItemWasteAnalysisForLineChart:
        selectedLevelWithMenuItemServiceWasteAnalysis.topMenuItemWasteAnalysisForLineChart,
    });
  }

  onChangeToggleWeightCostButton(event, newSelectedToggleValue) {
    this.setState({ selectedToggleValueForWeightCost: newSelectedToggleValue });
  }

  onClickAwayTooltip() {
    this.closeTooltip();
  }

  onClickInfoIcon() {
    const { isTooltipOpened } = this.state;

    this.setState({ isTooltipOpened: !isTooltipOpened });
  }

  onCloseTooltip() {
    this.closeTooltip();
  }

  onClickClearSelectionButton() {
    this.clearSelectedRow();
  }

  /**
   * This function filters out menu item(s) that is already on the watchlist from arrSelectedMenuItemServiceWasteAnalysis and passes it to
   * the addToWatchlist function. Also set state to unselect the menu item(s).
   */
  onClickAddMenuItemToWatchlist() {
    const { arrMenuItemRestaurantWasteAnalysis, arrSelectedMenuItemServiceWasteAnalysis } =
      this.state;
    const arrMenuItemRestaurantWasteAnalysisToBeAdded = [];

    arrSelectedMenuItemServiceWasteAnalysis.forEach((selectedMenuItemServiceWasteAnalysis) => {
      const matchedMenuItemRestaurantWasteAnalysis = arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId ===
            selectedMenuItemServiceWasteAnalysis.menuItemId &&
          menuItemRestaurantWasteAnalysis.serviceId ===
            selectedMenuItemServiceWasteAnalysis.serviceId
      );
      if (!this.isMenuItemAlreadyOnWatchlist(matchedMenuItemRestaurantWasteAnalysis)) {
        arrMenuItemRestaurantWasteAnalysisToBeAdded.push(matchedMenuItemRestaurantWasteAnalysis);
      }
    });

    this.addToWatchlist(arrMenuItemRestaurantWasteAnalysisToBeAdded);
    this.setState({ arrSelectedMenuItemServiceWasteAnalysis: [] });
  }

  /**
   * Display DAY, WEEK, or MONTH depending on the context
   */
  getGroupByText() {
    const { selectedGroupBy } = this.context;
    if (selectedGroupBy === CONSTANT.groupByDay) {
      return 'DAY';
    }
    if (selectedGroupBy === CONSTANT.groupByWeek) {
      return 'WEEK';
    }
    return 'MONTH';
  }

  /**
   * Get an array of location/service group options for dropdown
   */
  getArrDropdownLocation() {
    const { auth0 } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;
    const { selectedRestaurantService, arrLevelWithMenuItemServiceWasteAnalysis } = this.state;
    let arrDropdownItem = [];
    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      if (selectedRestaurantService) {
        arrDropdownItem = selectedRestaurantService.arrLocationService.map((locationService) => {
          return {
            value: locationService.locationId,
            name: locationService.name,
          };
        });
      }
    } else if (arrLevelWithMenuItemServiceWasteAnalysis.length !== 0) {
      arrDropdownItem = arrLevelWithMenuItemServiceWasteAnalysis.map(
        (levelWithMenuItemServiceWasteAnalysis) => {
          return {
            value: levelWithMenuItemServiceWasteAnalysis.name,
            name: levelWithMenuItemServiceWasteAnalysis.name,
          };
        }
      );
    }
    return arrDropdownItem;
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * This function fetches selectedMenuItemWasteAnalysisForCharts when menu item row(s) are checked or when the row(s) are filtered by service/station/search bar input.
   * If the update is for checked menu item row(s), the line graph is to display waste analysis of all the checked menu item(s). If the update is for filtered menu item row(s), the line graph
   * is to display waste analysis of the top x menu item(s) of the filtered menu item(s).
   * @param {typedefs.MenuItemServiceWasteAnalysis[]} arrSelectedMenuItemServiceWasteAnalysis - Array of menu item service waste analysis to chart into their group by format
   * @param {boolean} isFilter - Indicates if this function is called due to a change in the table rows because of a new filter being applied
   */
  async fetchSelectedMenuItemWasteAnalysisForCharts(
    arrSelectedMenuItemServiceWasteAnalysis,
    isFilter
  ) {
    const { openSnackbar, selectedGroupBy } = this.context;
    const { auth0, history } = this.props;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-selected-menu-item-waste-analysis-for-charts',
        {
          userId,
          arrMenuItemServiceWasteAnalysis: arrSelectedMenuItemServiceWasteAnalysis,
          groupBy: selectedGroupBy,
          ...(isFilter && {
            numberOfTopWastedFoodItemsToDisplay: CONSTANT.numberOfTopWastedFoodItemsToDisplay,
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { selectedMenuItemWasteAnalysisForCharts } = response.data;
      const { menuItemWasteAnalysisForLineChart } = selectedMenuItemWasteAnalysisForCharts;
      this.setState({
        selectedMenuItemWasteAnalysisForLineChart: menuItemWasteAnalysisForLineChart,
      });
      if (isFilter) {
        this.setState({
          arrSelectedMenuItemServiceWasteAnalysis: [],
          selectedMenuItemWasteAnalysisForLineChart: menuItemWasteAnalysisForLineChart,
          topMenuItemWasteAnalysisForLineChart: menuItemWasteAnalysisForLineChart,
        });
      } else {
        this.setState({
          arrSelectedMenuItemServiceWasteAnalysis,
          selectedMenuItemWasteAnalysisForLineChart: menuItemWasteAnalysisForLineChart,
        });
      }
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during fetching selected menu item waste analysis for charts. Please notify admin.',
          'error'
        );
      }
    }
  }

  clearSelectedRow() {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: [],
    });
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  /**
   * Check if a menu item is already on the watchlist
   * @param {typedefs.MenuItemServiceWasteAnalysis} menuItemServiceWasteAnalysis - Menu item to be verified if it is already on the watchlist
   * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
   */
  isMenuItemAlreadyOnWatchlist(menuItemServiceWasteAnalysis) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    const matchedWatchlistItemMenuItemRestaurantWasteAnalysis =
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.find(
        (watchlistItemMenuItemRestaurantWasteAnalysis) =>
          menuItemServiceWasteAnalysis.menuItemName ===
          watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis[0]
            .menuItemName
      );

    if (!matchedWatchlistItemMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    const matchedMenuItemRestaurantWasteAnalysis =
      matchedWatchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId === menuItemServiceWasteAnalysis.menuItemId
      );

    if (!matchedMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    return true;
  }

  /**
   * This function updates the graph and table states when there is a change in the checked menu item row(s) or a change in the service/station/search bar input filter.
   * @param {typedefs.MenuItemServiceWasteAnalysis[]} arrUpdatedSelectedMenuItemServiceWasteAnalysis - Array of updated checked menu item service waste analysis row(s) or filtered menu item service waste analysis row(s)
   * @param {boolean} isFilter - Indicates if this function is called due to a change in the table rows because of a new filter being applied
   */
  updateGraphAndTableForArrSelectedMenuItemServiceWasteAnalysis(
    arrUpdatedSelectedMenuItemServiceWasteAnalysis,
    isFilter
  ) {
    const { openSnackbar } = this.context;
    // If this function is called due to a change in the checked menu item row(s) and the number of checked row(s) in the table is more than CONSTANT.foodItemsBreakdownSelectionMaxSize, an error message pops up.
    // This is to prevent too many lines on the graph, making it hard to read.
    if (
      !isFilter &&
      arrUpdatedSelectedMenuItemServiceWasteAnalysis.length >
        CONSTANT.foodItemsBreakdownSelectionMaxSize
    ) {
      openSnackbar(
        `You can only select up to ${CONSTANT.foodItemsBreakdownSelectionMaxSize} food items at a time`,
        'error'
      );
      return;
    }

    const { topMenuItemWasteAnalysisForLineChart } = this.state;

    // If this function is called due to a change in the checked menu item row(s) and none of the rows in the table is checked, show default top 6 menu item waste analysis of the selected location/service group
    if (!isFilter && arrUpdatedSelectedMenuItemServiceWasteAnalysis.length === 0) {
      this.setState({
        arrSelectedMenuItemServiceWasteAnalysis: [],
        selectedMenuItemWasteAnalysisForLineChart: topMenuItemWasteAnalysisForLineChart,
      });
    } else {
      this.fetchSelectedMenuItemWasteAnalysisForCharts(
        arrUpdatedSelectedMenuItemServiceWasteAnalysis,
        isFilter
      );
    }
  }

  /**
   * Download table data in csv format. The fields include details of the menu item(s) of the selected location/service group, which includes the
   * menu item name, service name, station, total weight, waste per cover, % change in waste per cover, total cost, cost per cover and % change in cost per cover.
   * Note: All numbers are rounded off to 2 dp
   */
  downloadTableData() {
    const { companyName, selectedEndDate, selectedStartDate } = this.context;
    const { match } = this.props;
    const { selectedRestaurantService, selectedLevelWithMenuItemServiceWasteAnalysis } = this.state;
    const arrHeader = [
      'ITEM',
      'SERVICE',
      'STATION',
      'WEIGHT (KG)',
      'WASTE PER COVER (GRAMS)',
      '% CHANGE IN WASTE PER COVER',
      `COST (${selectedLevelWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis[0].currency})`,
      `COST PER COVER (${selectedLevelWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis[0].currency})`,
      '% CHANGE IN COST PER COVER',
    ];
    const arrRowData =
      selectedLevelWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis.map(
        (menuItemServiceWasteAnalysis) => {
          return {
            [`${arrHeader[0]}`]: menuItemServiceWasteAnalysis.menuItemName,
            [`${arrHeader[1]}`]: menuItemServiceWasteAnalysis.serviceName,
            [`${arrHeader[2]}`]: menuItemServiceWasteAnalysis.station,
            [`${arrHeader[3]}`]: menuItemServiceWasteAnalysis.weight.toFixed(2),
            [`${arrHeader[4]}`]:
              typeof menuItemServiceWasteAnalysis.weightPerCover === 'number'
                ? menuItemServiceWasteAnalysis.weightPerCover.toFixed(2)
                : menuItemServiceWasteAnalysis.weightPerCover,
            [`${arrHeader[5]}`]:
              typeof menuItemServiceWasteAnalysis.weightPerCoverDifferencePercentage === 'number'
                ? menuItemServiceWasteAnalysis.weightPerCoverDifferencePercentage.toFixed(2)
                : menuItemServiceWasteAnalysis.weightPerCoverDifferencePercentage,
            [`${arrHeader[6]}`]: menuItemServiceWasteAnalysis.cost.toFixed(2),
            [`${arrHeader[7]}`]:
              typeof menuItemServiceWasteAnalysis.costPerCover === 'number'
                ? menuItemServiceWasteAnalysis.costPerCover.toFixed(2)
                : menuItemServiceWasteAnalysis.costPerCover,
            [`${arrHeader[8]}`]:
              typeof menuItemServiceWasteAnalysis.costPerCoverDifferencePercentage === 'number'
                ? menuItemServiceWasteAnalysis.costPerCoverDifferencePercentage.toFixed(2)
                : menuItemServiceWasteAnalysis.costPerCoverDifferencePercentage,
          };
        }
      );
    const selectedLocationId = parseInt(match.params.locationId, 10);
    let locationOrLocationServiceStringToAddToFilename = '';
    if (selectedLocationId) {
      const { name: selectedLocationName } = selectedRestaurantService.arrLocationService.find(
        (locationService) => locationService.locationId === selectedLocationId
      );
      locationOrLocationServiceStringToAddToFilename = `${selectedLocationName}_${selectedLevelWithMenuItemServiceWasteAnalysis.name}`;
    } else {
      locationOrLocationServiceStringToAddToFilename = `${selectedLevelWithMenuItemServiceWasteAnalysis.name}`;
    }
    const options = {
      filename: `${companyName}_${selectedRestaurantService.name}_${locationOrLocationServiceStringToAddToFilename}_${selectedStartDate}_${selectedEndDate}_FoodItem`,
      quoteStrings: '',
      showLabels: true,
      columnHeaders: arrHeader,
    };

    const cvsConfig = mkConfig(options);
    const cvsData = generateCsv(cvsConfig)(arrRowData);
    download(cvsConfig)(cvsData);
  }

  /**
   * This function fetches the user's menu item restaurant and watchlist items on component mount and update
   * and sets the states arrMenuItemRestaurantWasteAnalysis and arrWatchlistItemMenuItemRestaurantWasteAnalysis accordingly
   */
  async fetchWatchlistItems(arrSelectedRestaurantService) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;
    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-arr-menu-item-restaurant-waste-analysis-and-arr-watchlist-item-menu-item-restaurant-waste-analysis',
        {
          userId,
          arrRestaurantService: arrSelectedRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        arrMenuItemRestaurantWasteAnalysis,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      } = response.data;
      // arrMenuItemRestaurantWasteAnalysis is for addding menu item(s) to watchlist
      this.setState({
        arrMenuItemRestaurantWasteAnalysis,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else if (error?.response?.data.error === CONSTANT.calculatorConnectionError) {
        openSnackbar(
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
          'error'
        );
      } else {
        openSnackbar(
          'Unknown error during loading for food items wastage. Please notify admin.',
          'error'
        );
      }
    }
  }

  /**
   * To send the menu item(s) to be added to the watchlist to the Backend for adding to the database. If the number of watchlist items is already at its maximum watchlist
   * size of 20, a snackbar will apear to inform the user and the menu item(s) will not be sent to the Backend.
   * ToDo: Shift this function to the child component during optimization (Ref: #85)
   * @param {typedefs.MenuItemRestaurantWasteAnalysis[]} arrMenuItemRestaurantWasteAnalysisToBeAdded - Array of menu item(s) to be added to watchlist
   */
  async addToWatchlist(arrMenuItemRestaurantWasteAnalysisToBeAdded) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { arrMenuItemRestaurantWasteAnalysis, arrWatchlistItemMenuItemRestaurantWasteAnalysis } =
      this.state;
    const { auth0 } = this.props;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length >= 20) {
      openSnackbar(
        `You can only have a maximum of ${CONSTANT.watchlistMaxSize} items in your watchlist`,
        'error'
      );
    } else if (arrMenuItemRestaurantWasteAnalysisToBeAdded.length === 0) {
      openSnackbar(`Selected food item(s) are already on the watchlist`, 'error');
    } else {
      try {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        const response = await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantWasteAnalysisToBeAdded,
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurantWasteAnalysis: arrMenuItemRestaurantWasteAnalysis,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        this.setState({
          arrWatchlistItemMenuItemRestaurantWasteAnalysis:
            data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        });
        renderLoaderAnimation(false);
        openSnackbar(
          'Successfully added ' +
            `${arrMenuItemRestaurantWasteAnalysisToBeAdded.length}` +
            ' food item' +
            `${arrMenuItemRestaurantWasteAnalysisToBeAdded.length > 1 ? 's' : ''}` +
            ' to the watchlist',
          'success'
        );
      } catch (error) {
        openSnackbar(
          'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  /**
   * This function fetches arrLevelWithMenuItemServiceWasteAnalysis on mount and update and sets the states
   * arrLevelWithMenuItemServiceWasteAnalysis, isArrLevelWithMenuItemServiceWasteAnalysisFetched and
   * selectedLevelWithMenuItemServiceWasteAnalysis accordingly.
   * @param {typedefs.locationService[]} arrLocationService - Array of locationService
   */
  async fetchArrLocationWithMenuItemServiceWasteAnalysis(arrLocationService) {
    const {
      impersonatorIsCompanyUser,
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;

    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-arr-level-with-menu-item-service-waste-analysis',
        {
          userId,
          arrLocationService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
          numberOfTopWastedFoodItemsToDisplay: CONSTANT.numberOfTopWastedFoodItemsToDisplay,
          isToIncludeTopWasteImage: true,
          isCompanyUser:
            (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { arrLevelWithMenuItemServiceWasteAnalysis } = response.data;
      this.setState({
        arrLevelWithMenuItemServiceWasteAnalysis,
        isArrLevelWithMenuItemServiceWasteAnalysisFetched: true,
        selectedLevelWithMenuItemServiceWasteAnalysis: arrLevelWithMenuItemServiceWasteAnalysis[0],
        topMenuItemWasteAnalysisForLineChart:
          arrLevelWithMenuItemServiceWasteAnalysis[0].topMenuItemWasteAnalysisForLineChart,
        selectedMenuItemWasteAnalysisForLineChart:
          arrLevelWithMenuItemServiceWasteAnalysis[0].topMenuItemWasteAnalysisForLineChart,
        arrSelectedMenuItemServiceWasteAnalysis: [],
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else if (error?.response?.data.error === CONSTANT.calculatorConnectionError) {
        openSnackbar(
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
          'error'
        );
      } else {
        openSnackbar(
          'Unknown error during fetching food items waste analysis. Please notify admin.',
          'error'
        );
      }
    }
  }

  render() {
    const { classes, auth0 } = this.props;
    const {
      selectedLevelWithMenuItemServiceWasteAnalysis,
      isArrLevelWithMenuItemServiceWasteAnalysisFetched,
      arrSelectedMenuItemServiceWasteAnalysis,
      isTooltipOpened,
      selectedToggleValueForWeightCost,
      selectedMenuItemWasteAnalysisForLineChart,
    } = this.state;
    const {
      currency,
      impersonatorIsCompanyUser,
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
    } = this.context;
    const { user } = auth0;
    const isCompanyUser =
      (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);

    let xAxisDataForLineChart = [];
    let yAxisDataForLineChart = [];
    let labelDataForLineChart = [];
    if (isArrLevelWithMenuItemServiceWasteAnalysisFetched) {
      if (selectedToggleValueForWeightCost === 'weight') {
        xAxisDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byWeight.arrDate;
        yAxisDataForLineChart =
          selectedMenuItemWasteAnalysisForLineChart.byWeight.arrArrWeightByMenuItem;
        labelDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byWeight.arrMenuItemName;
      } else {
        xAxisDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byCost.arrDate;
        yAxisDataForLineChart =
          selectedMenuItemWasteAnalysisForLineChart.byCost.arrArrCostByMenuItem;
        labelDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byCost.arrMenuItemName;
      }
    }
    return (
      <Box>
        {isArrLevelWithMenuItemServiceWasteAnalysisFetched && (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing={0}
            className={classes.rootGridContainer}
          >
            <Grid item className={classes.rootGridItem}>
              <Card className={classes.rootCard}>
                {/* Page title */}
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  spacing={0}
                  className={classes.rootCardGridContainer}
                >
                  <Grid item className={classes.rootCardGridItemTitle}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Grid container>
                          <Typography variant="h1" color="primary" className={classes.spacedTitle}>
                            BREAKDOWN BY FOOD ITEMS
                          </Typography>
                          <ClickAwayListener onClickAway={() => this.onClickAwayTooltip()}>
                            <Tooltip
                              open={isTooltipOpened}
                              onClose={() => this.onCloseTooltip()}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              title={
                                <Typography variant="caption">
                                  <p>This shows the wastage data of food items.</p>
                                  <p>
                                    You may check on multiple food items to add them to your
                                    watchlist.
                                  </p>
                                  <p>
                                    Percentage change values are derived from comparing data of the
                                    previous date range
                                    <b>
                                      {getPreviousDateRangeString(
                                        selectedStartDate,
                                        selectedEndDate,
                                        selectedGroupBy
                                      )}
                                    </b>{' '}
                                    and the current selected date range
                                    <b>{` ${format(
                                      new Date(selectedStartDate),
                                      'dd-MM-yyyy'
                                    )} — ${format(new Date(selectedEndDate), 'dd-MM-yyyy')}`}</b>
                                    .
                                  </p>
                                </Typography>
                              }
                            >
                              <InfoIcon
                                onClick={() => this.onClickInfoIcon()}
                                className={classes.tooltipIcon}
                              />
                            </Tooltip>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classes.downloadButton}
                          variant={
                            selectedLevelWithMenuItemServiceWasteAnalysis
                              .arrMenuItemServiceWasteAnalysis.length === 0
                              ? 'outlined'
                              : 'contained'
                          }
                          disabled={
                            selectedLevelWithMenuItemServiceWasteAnalysis
                              .arrMenuItemServiceWasteAnalysis.length === 0
                          }
                          onClick={() => this.downloadTableData()}
                        >
                          <GetAppRoundedIcon />
                          <Typography variant="body4">Download Table</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Location dropdown and weight/cost toggle */}
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <DropdownList
                          label={isCompanyUser ? 'Location' : 'Service Group'}
                          backgroundColor="white"
                          arrDropdownItem={this.getArrDropdownLocation()}
                          selectedValue={
                            isCompanyUser
                              ? selectedLevelWithMenuItemServiceWasteAnalysis.locationId
                              : selectedLevelWithMenuItemServiceWasteAnalysis.name
                          }
                          onChange={(event) => this.onChangeDropdownList(event)}
                        />
                      </Grid>
                      <Grid item>
                        <ToggleWeightCostButton
                          selectedToggleValue={selectedToggleValueForWeightCost}
                          onChange={(event, newSelectedToggleValue) =>
                            this.onChangeToggleWeightCostButton(event, newSelectedToggleValue)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Waste Analysis by menu items for Line Chart */}
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    className={classes.rootCardGridItemLineChart}
                  >
                    <Grid item className={classes.rootCardGridSubtitle}>
                      <Typography variant="h2" color="primary" align="center">
                        FOOD ITEMS WASTAGE BY {this.getGroupByText()}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <LineChart
                        xAxisData={xAxisDataForLineChart}
                        yAxisData={yAxisDataForLineChart}
                        yAxisLabel={
                          selectedToggleValueForWeightCost === 'weight'
                            ? 'WEIGHT (KG)'
                            : `COST (${currency})`
                        }
                        labelData={labelDataForLineChart}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <FoodItemsBreakdownTable
                      arrMenuItemServiceWasteAnalysis={
                        selectedLevelWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis
                      }
                      arrSelectedMenuItemServiceWasteAnalysis={
                        arrSelectedMenuItemServiceWasteAnalysis
                      }
                      toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      onClickClearSelection={() => this.onClickClearSelectionButton()}
                      onClickAdd={() => this.onClickAddMenuItemToWatchlist()}
                      updateGraphAndTableForArrSelectedMenuItemServiceWasteAnalysis={(
                        arrUpdatedSelectedMenuItemServiceWasteAnalysis,
                        isFilter
                      ) =>
                        this.updateGraphAndTableForArrSelectedMenuItemServiceWasteAnalysis(
                          arrUpdatedSelectedMenuItemServiceWasteAnalysis,
                          isFilter
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}
FoodItemsBreakdown.contextType = AppContext;

export default withAuth0(withStyles(styles)(FoodItemsBreakdown));
