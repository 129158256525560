import React, { PureComponent } from 'react';
import CustomTable from '../common/CustomTable';

class StationsBreakdownTable extends PureComponent {
  render() {
    const {
      arrStationServiceWasteAnalysis,
      toggleValueForWeightCost,
      arrSelectedStationServiceWasteAnalysis,
      updateGraphAndTableForArrSelectedStationServiceWasteAnalysis,
    } = this.props;

    const weightCostId = toggleValueForWeightCost;
    const weightCostHeader = toggleValueForWeightCost === 'weight' ? 'WEIGHT' : 'COST';
    const weightCostDifferenceId =
      toggleValueForWeightCost === 'weight'
        ? 'weightDifferencePercentage'
        : 'costDifferencePercentage';
    const weightCostDifferenceHeader =
      toggleValueForWeightCost === 'weight' ? '% CHANGE IN WEIGHT' : '% CHANGE IN COST';
    const arrTableHeader = [
      { id: 'station', label: 'STATION', iconName: 'filter' },
      { id: 'serviceName', label: 'SERVICE', iconName: 'filter' },
      { id: weightCostId, label: weightCostHeader, iconName: 'sort' },
      { id: weightCostDifferenceId, label: weightCostDifferenceHeader, iconName: 'sort' },
    ];
    const arrTableAlignment = ['left', 'center', 'center', 'center'];
    const arrTableWrap = [true, true, false, false];
    const arrIdKey = ['station', 'serviceId'];
    const arrTableRow = arrStationServiceWasteAnalysis;
    const arrColumnWidth = ['40%', '30%', '15%', '15%'];

    return (
      <div>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrIdKey={arrIdKey}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          hasFilterAndSortFunctionAndHeaderIcons
          hasPagination
          hasSelectAllCheckboxInHeader
          updateArrSelectedRow={updateGraphAndTableForArrSelectedStationServiceWasteAnalysis}
          arrSelectedRow={arrSelectedStationServiceWasteAnalysis}
          rowsPerPage={5}
          hasCheckboxInBody
          arrColumnWidth={arrColumnWidth}
        />
      </div>
    );
  }
}

export default StationsBreakdownTable;
