import axios from 'axios';
import { format } from 'date-fns';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { Box, Button, Card, ClickAwayListener, Grid, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import StationsBreakdownTable from './StationsBreakdownTable';
import BarChart from '../common/chart/BarChart';
import LineChart from '../common/chart/LineChart';
import CommonFunctions from '../common/CommonFunctions';
import DropdownList from '../common/DropdownList';
import ToggleWeightCostButton from '../common/ToggleWeightCostButton';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

const { getPreviousDateRangeString } = CommonFunctions;

const styles = (theme) => ({
  spacedTitle: theme.typography.spacedTitle,
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootGridItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCard: {
    ...theme.card.home,
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridItemDefault: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridItemTitle: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootCardGridSubtitle: {
    paddingTop: '10px',
  },
  rootCardGridItemBarChart: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingRight: '10px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '30px',
    },
  },
  rootCardGridItemLineChart: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
    },
  },
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
  },
  downloadButton: {
    ...theme.typography.h6,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '7px',
    color: '#50655b',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#088280',
      color: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: 'white',
      color: theme.palette.gray3,
    },
  },
});

/**
 * Cut off each axis label based on max number of characters specified
 * @param {string[]} arrAxisLabel - Array of axis label
 * @param {number} maxLength - Max number of axis label characters to be displayed
 * @returns {string[]} Array of axis label where each label has a maximum length as indicated
 */
const renderStemmedAxisLabels = (arrAxisLabel, maxLength) => {
  return arrAxisLabel.map((axisLabel) => {
    if (axisLabel.length > maxLength) {
      return `${axisLabel.slice(0, maxLength)}...`;
    }
    return axisLabel;
  });
};

class StationsBreakdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // If fetching data is done
      isArrLevelWithStationWasteAnalysisFetched: false,

      // Waste Analysis for stations
      arrLevelWithStationServiceWasteAnalysis: [],
      arrStationServiceWasteAnalysisForTable: {}, // This holds all the stationServiceWasteAnalysis for the selected location/service group
      topThreeStationWasteAnalysisForBarChart: {}, // This is the top 3 stations bar chart for the selected location/service group, or for the filtered rows
      topThreeStationWasteAnalysisForLineChart: {}, // This is the top 3 stations line chart for the selected location/service group, or for the filtered rows
      selectedStationWasteAnalysisForBarChart: {}, // This is the bar chart for the checked rows
      selectedStationWasteAnalysisForLineChart: {}, // This is the line chart for the checked rows
      arrSelectedStationServiceWasteAnalysis: [], // This holds all the checked rows

      // Selected values
      selectedRestaurantService: null,
      selectedLocationId: '',
      selectedServiceGroup: '',
      selectedToggleValueForWeightCost: 'weight', // 'weight' or 'cost'
      isTooltipOpened: false,

      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',
    };
  }

  componentDidMount() {
    document.title = 'Stations Breakdown | Lumitics | Towards Zero Food Waste';
    const { match, arrRestaurantService } = this.props;
    const { setPageHistory } = this.context;

    let arrLocationServiceToFetchStationWasteAnalysis = [];
    let selectedLocationId = 0;
    let pageHeaderString = '';
    const selectedRestaurantId = parseInt(match.params.restaurantId, 10);
    const selectedRestaurantService = arrRestaurantService.find(
      (restaurantLocation) => restaurantLocation.restaurantId === selectedRestaurantId
    );
    if (match.params.locationId) {
      selectedLocationId = parseInt(match.params.locationId, 10);
      const selectedLocationService = selectedRestaurantService.arrLocationService.find(
        (locationService) => locationService.locationId === selectedLocationId
      );
      arrLocationServiceToFetchStationWasteAnalysis = [selectedLocationService];
      pageHeaderString = `${selectedRestaurantService.name} - ${selectedLocationService.name}`;
    } else {
      selectedLocationId = selectedRestaurantService.arrLocationService[0].locationId;
      arrLocationServiceToFetchStationWasteAnalysis = selectedRestaurantService.arrLocationService;
      pageHeaderString = selectedRestaurantService.name;
    }

    this.setState({
      selectedRestaurantService,
      selectedLocationId,
    });
    setPageHistory([`${pageHeaderString}: ${CONSTANT.breakdownStationsPage}`]);
    this.setPreviousSelectedStartEndDateAndGroupBy();
    this.fetchArrLocationWithStationWasteAnalysis(
      arrLocationServiceToFetchStationWasteAnalysis,
      selectedLocationId
    );
  }

  componentDidUpdate() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    const {
      previousSelectedStartDate,
      previousSelectedEndDate,
      previousSelectedGroupBy,
      selectedRestaurantService,
      selectedLocationId,
    } = this.state;

    /* 
      Comparing the context directly in the following way:
      prevProps.context !== this.context     because 
      prevProps.context.selectedStartDate !== this.context.selectedStartDate
      somehow cause infinite loop and multiple calls to fetchArrLocationWithStationWasteAnalysis()
     */
    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      // Update previousSelectedStartDate and previousSelectedEndDate with the new context ones
      this.setPreviousSelectedStartEndDateAndGroupBy();

      this.fetchArrLocationWithStationWasteAnalysis(
        selectedRestaurantService.arrLocationService,
        selectedLocationId
      );
    }
  }

  /**
   * Change selected location/service group
   */
  onChangeDropdownList(event) {
    const { auth0 } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;
    const { arrLevelWithStationServiceWasteAnalysis } = this.state;
    const { value } = event.target;

    let selectedLevelWithStationServiceWasteAnalysis = null;
    let selectedLocationId = '';
    let selectedServiceGroup = '';
    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      selectedLevelWithStationServiceWasteAnalysis = arrLevelWithStationServiceWasteAnalysis.find(
        (levelWithStationWasteAnalysis) => levelWithStationWasteAnalysis.locationId === value
      );
      selectedLocationId = value;
    } else {
      selectedLevelWithStationServiceWasteAnalysis = arrLevelWithStationServiceWasteAnalysis.find(
        (levelWithStationWasteAnalysis) => levelWithStationWasteAnalysis.name === value
      );
      selectedServiceGroup = value;
    }

    this.setState({
      selectedLocationId,
      selectedServiceGroup,
      arrStationServiceWasteAnalysisForTable:
        selectedLevelWithStationServiceWasteAnalysis.arrStationServiceWasteAnalysis,
      topThreeStationWasteAnalysisForBarChart:
        selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForBarChart,
      topThreeStationWasteAnalysisForLineChart:
        selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForLineChart,
      selectedStationWasteAnalysisForBarChart:
        selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForBarChart,
      selectedStationWasteAnalysisForLineChart:
        selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForLineChart,
    });
  }

  onChangeToggleWeightCostButton(event, newSelectedToggleValue) {
    this.setState({ selectedToggleValueForWeightCost: newSelectedToggleValue });
  }

  onClickInfoIcon() {
    const { isTooltipOpened } = this.state;
    this.setState({ isTooltipOpened: !isTooltipOpened });
  }

  onClickAwayTooltip() {
    this.closeTooltip();
  }

  onCloseTooltip() {
    this.closeTooltip();
  }

  /**
   * Get an array of location/ service group options for dropdown
   */
  getArrDropdownLocation() {
    const { auth0 } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;
    const { selectedRestaurantService, arrLevelWithStationServiceWasteAnalysis } = this.state;
    let arrDropdownItem = [];
    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      if (selectedRestaurantService) {
        arrDropdownItem = selectedRestaurantService.arrLocationService.map((locationService) => {
          return {
            value: locationService.locationId,
            name: locationService.name,
          };
        });
      }
    } else if (arrLevelWithStationServiceWasteAnalysis.length !== 0) {
      arrDropdownItem = arrLevelWithStationServiceWasteAnalysis.map(
        (groupedServiceWithStationServiceWasteAnalysis) => {
          return {
            value: groupedServiceWithStationServiceWasteAnalysis.name,
            name: groupedServiceWithStationServiceWasteAnalysis.name,
          };
        }
      );
    }
    return arrDropdownItem;
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * Display DAY, WEEK, or MONTH depending on the context
   */
  getGroupByText() {
    const { selectedGroupBy } = this.context;
    if (selectedGroupBy === CONSTANT.groupByDay) {
      return 'DAY';
    }
    if (selectedGroupBy === CONSTANT.groupByWeek) {
      return 'WEEK';
    }
    return 'MONTH';
  }

  /**
   * This function is called when there is a change in the checked table row(s) or by filtering by station/service. If this function is called due to a change in the checked station row(s)
   * and there is no selected table row, the bar chart and line chart will display the waste analysis of the top three stations of the selected location/service group.
   * Else, it will call the function to find formulate the data to display the bar and line chart for the specific selected stations.
   * @param {typedefs.StationServiceWasteAnalysis[]} arrUpdatedSelectedStationServiceWasteAnalysis - Array of updated checked station service waste analysis row(s) or filtered station service waste analysis row(s)
   * @param {boolean} isFilter - Indicates if this function is called due to a change in the table rows because of a new filter being applied
   */
  updateGraphAndTableForArrSelectedStationServiceWasteAnalysis(
    arrUpdatedSelectedStationServiceWasteAnalysis,
    isFilter
  ) {
    const { topThreeStationWasteAnalysisForBarChart, topThreeStationWasteAnalysisForLineChart } =
      this.state;
    // If this function is not called by the filtering function in CustomTable component and none of the rows in the table is checked, show default top 3 station waste analysis
    if (!isFilter && arrUpdatedSelectedStationServiceWasteAnalysis.length === 0) {
      this.setState({
        arrSelectedStationServiceWasteAnalysis: [],
        selectedStationWasteAnalysisForBarChart: topThreeStationWasteAnalysisForBarChart,
        selectedStationWasteAnalysisForLineChart: topThreeStationWasteAnalysisForLineChart,
      });
    } else {
      this.fetchSelectedStationWasteAnalysisForCharts(
        arrUpdatedSelectedStationServiceWasteAnalysis,
        isFilter
      );
    }
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  /**
   * Download table data in csv format. The fields include details of the menu item(s) of the selected location, which includes the
   * menu item name, service name, station, total weight, % change in weight, total cost, and % change in cost.
   * Note: All numbers are rounded off to 2 dp
   */
  downloadTableData() {
    const { companyName, selectedEndDate, selectedStartDate } = this.context;
    const {
      selectedLocationId,
      selectedRestaurantService,
      selectedServiceGroup,
      arrStationServiceWasteAnalysisForTable,
    } = this.state;
    const selectedRestaurantName = selectedRestaurantService.name;
    const { name: selectedLocationName } = selectedRestaurantService.arrLocationService.find(
      (locationService) => locationService.locationId === selectedLocationId
    );
    const arrHeader = [
      'STATION',
      'SERVICE',
      'WEIGHT (KG)',
      '% CHANGE IN WEIGHT',
      `COST (${arrStationServiceWasteAnalysisForTable[0].currency})`,
      '% CHANGE IN COST',
    ];
    const arrRowData = arrStationServiceWasteAnalysisForTable.map((stationServiceWasteAnalysis) => {
      return {
        [`${arrHeader[0]}`]: stationServiceWasteAnalysis.station,
        [`${arrHeader[1]}`]: stationServiceWasteAnalysis.serviceName,
        [`${arrHeader[2]}`]: stationServiceWasteAnalysis.weight.toFixed(2),
        [`${arrHeader[3]}`]:
          typeof stationServiceWasteAnalysis.weightDifferencePercentage === 'number'
            ? stationServiceWasteAnalysis.weightDifferencePercentage.toFixed(2)
            : stationServiceWasteAnalysis.weightDifferencePercentage,
        [`${arrHeader[4]}`]: stationServiceWasteAnalysis.cost.toFixed(2),
        [`${arrHeader[5]}`]:
          typeof stationServiceWasteAnalysis.costDifferencePercentage === 'number'
            ? stationServiceWasteAnalysis.costDifferencePercentage.toFixed(2)
            : stationServiceWasteAnalysis.costDifferencePercentage,
      };
    });
    const options = {
      filename: `${companyName}_${selectedRestaurantName}_${selectedLocationName}_${
        selectedServiceGroup ? `${selectedServiceGroup}_` : ''
      }${selectedStartDate}_${selectedEndDate}_Station`,
      quoteStrings: '',
      showLabels: true,
      columnHeaders: arrHeader,
    };

    const cvsConfig = mkConfig(options);
    const cvsData = generateCsv(cvsConfig)(arrRowData);
    download(cvsConfig)(cvsData);
  }

  /**
   * Fetch arrLevelWithStationServiceWasteAnalysis from the backend for the all locations of the selected restaurant. Set state with the waste analysis of the
   * selected location.
   * @param {typedefs.LocationService[]} arrLocationService - Array of locationService to find waste analysis for
   * @param {number} selectedLocationId - Id of selected location
   */
  async fetchArrLocationWithStationWasteAnalysis(arrLocationService, selectedLocationId) {
    const {
      impersonatorIsCompanyUser,
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;
    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const isCompanyUser =
        (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);
      const response = await axios.post(
        '/api/fetch-arr-level-with-station-service-waste-analysis',
        {
          userId,
          arrLocationService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
          isCompanyUser,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { arrLevelWithStationServiceWasteAnalysis } = response.data;

      let selectedLevelWithStationServiceWasteAnalysis = null;
      let selectedServiceGroup = '';
      if (isCompanyUser) {
        selectedLevelWithStationServiceWasteAnalysis = arrLevelWithStationServiceWasteAnalysis.find(
          (levelWithStationWasteAnalysis) =>
            levelWithStationWasteAnalysis.locationId === selectedLocationId
        );
      } else {
        [selectedLevelWithStationServiceWasteAnalysis] = arrLevelWithStationServiceWasteAnalysis;
        selectedServiceGroup = selectedLevelWithStationServiceWasteAnalysis.name;
      }
      this.setState({
        isArrLevelWithStationWasteAnalysisFetched: true,
        arrLevelWithStationServiceWasteAnalysis,
        arrStationServiceWasteAnalysisForTable:
          selectedLevelWithStationServiceWasteAnalysis.arrStationServiceWasteAnalysis,
        topThreeStationWasteAnalysisForBarChart:
          selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForBarChart,
        topThreeStationWasteAnalysisForLineChart:
          selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForLineChart,
        selectedStationWasteAnalysisForBarChart:
          selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForBarChart,
        selectedStationWasteAnalysisForLineChart:
          selectedLevelWithStationServiceWasteAnalysis.topThreeStationWasteAnalysisForLineChart,
        selectedServiceGroup,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else if (error?.response?.data.error === CONSTANT.calculatorConnectionError) {
        openSnackbar(
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
          'error'
        );
      } else {
        openSnackbar(
          'Unknown error during fetching station waste analysis. Please notify admin.',
          'error'
        );
      }
    }
  }

  /**
   * This function fetches selectedStationWasteAnalysisForCharts when station row(s) are checked or when the row(s) are filtered by service/station.
   * If the update is for checked station row(s), the bar and line graphs are to display waste analysis of all the checked station(s). If the update is for filtered station row(s), the graphs
   * are to display waste analysis of the top 3 station(s) of the filtered station(s).
   * @param {typedefs.StationServiceWasteAnalysis[]} arrSelectedStationServiceWasteAnalysis - Array of station service waste analysis to chart into their group by format
   * @param {boolean} isFilter - Indicates if this function is called due to a change in the table rows because of a new filter being applied
   */
  async fetchSelectedStationWasteAnalysisForCharts(
    arrSelectedStationServiceWasteAnalysis,
    isFilter
  ) {
    const { openSnackbar, selectedGroupBy } = this.context;
    const { auth0, history } = this.props;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-selected-station-waste-analysis-for-charts',
        {
          userId,
          arrStationServiceWasteAnalysis: arrSelectedStationServiceWasteAnalysis,
          groupBy: selectedGroupBy,
          isTopThreeStation: isFilter,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { selectedStationWasteAnalysisForCharts } = response.data;
      const { stationWasteAnalysisForBarChart, stationWasteAnalysisForLineChart } =
        selectedStationWasteAnalysisForCharts;
      if (isFilter) {
        this.setState({
          arrSelectedStationServiceWasteAnalysis: [],
          topThreeStationWasteAnalysisForBarChart: stationWasteAnalysisForBarChart,
          topThreeStationWasteAnalysisForLineChart: stationWasteAnalysisForLineChart,
          selectedStationWasteAnalysisForBarChart: stationWasteAnalysisForBarChart,
          selectedStationWasteAnalysisForLineChart: stationWasteAnalysisForLineChart,
        });
      } else {
        this.setState({
          arrSelectedStationServiceWasteAnalysis,
          selectedStationWasteAnalysisForBarChart: stationWasteAnalysisForBarChart,
          selectedStationWasteAnalysisForLineChart: stationWasteAnalysisForLineChart,
        });
      }
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during fetching selected station waste analysis for charts. Please notify admin.',
          'error'
        );
      }
    }
  }

  render() {
    const {
      isArrLevelWithStationWasteAnalysisFetched,
      selectedStationWasteAnalysisForBarChart,
      selectedStationWasteAnalysisForLineChart,
      arrStationServiceWasteAnalysisForTable,
      selectedLocationId,
      selectedServiceGroup,
      selectedToggleValueForWeightCost,
      arrSelectedStationServiceWasteAnalysis,
      isTooltipOpened,
    } = this.state;
    const {
      currency,
      impersonatorIsCompanyUser,
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
    } = this.context;
    const { classes, auth0 } = this.props;
    const { user } = auth0;
    const isCompanyUser =
      (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);

    let xAxisDataForBarChart = [];
    let yAxisDataForBarChart = [];
    let labelDataForBarChart = [];
    let xAxisDataForLineChart = [];
    let yAxisDataForLineChart = [];
    let labelDataForLineChart = [];
    let arrStationServiceWasteAnalysisForTableToBeDisplayed = [];

    if (isArrLevelWithStationWasteAnalysisFetched) {
      arrStationServiceWasteAnalysisForTableToBeDisplayed = arrStationServiceWasteAnalysisForTable;
      if (selectedToggleValueForWeightCost === 'weight') {
        xAxisDataForBarChart = renderStemmedAxisLabels(
          selectedStationWasteAnalysisForBarChart.byWeight.arrStation,
          7
        );
        yAxisDataForBarChart = selectedStationWasteAnalysisForBarChart.byWeight.arrWeight;
        labelDataForBarChart = selectedStationWasteAnalysisForBarChart.byWeight.arrServiceName;
        xAxisDataForLineChart = selectedStationWasteAnalysisForLineChart.byWeight.arrDate;
        yAxisDataForLineChart =
          selectedStationWasteAnalysisForLineChart.byWeight.arrArrWeightByStation;
        labelDataForLineChart = selectedStationWasteAnalysisForLineChart.byWeight.arrStation;
      } else {
        xAxisDataForBarChart = renderStemmedAxisLabels(
          selectedStationWasteAnalysisForBarChart.byCost.arrStation,
          7
        );
        yAxisDataForBarChart = selectedStationWasteAnalysisForBarChart.byCost.arrCost;
        labelDataForBarChart = selectedStationWasteAnalysisForBarChart.byCost.arrServiceName;
        xAxisDataForLineChart = selectedStationWasteAnalysisForLineChart.byCost.arrDate;
        yAxisDataForLineChart = selectedStationWasteAnalysisForLineChart.byCost.arrArrCostByStation;
        labelDataForLineChart = selectedStationWasteAnalysisForLineChart.byCost.arrStation;
      }
    }
    return (
      <Box>
        {isArrLevelWithStationWasteAnalysisFetched && (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing={0}
            className={classes.rootGridContainer}
          >
            <Grid item className={classes.rootGridItem}>
              <Card className={classes.rootCard}>
                <Grid container direction="column" wrap="nowrap" spacing={0}>
                  <Grid item className={classes.rootCardGridItemTitle}>
                    {/* Title and tooltip container */}
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Grid container>
                          <Typography variant="h1" color="primary" className={classes.spacedTitle}>
                            BREAKDOWN BY STATIONS
                          </Typography>
                          <ClickAwayListener onClickAway={() => this.onClickAwayTooltip()}>
                            <Tooltip
                              open={isTooltipOpened}
                              onClose={() => this.onCloseTooltip()}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              title={
                                <Typography variant="caption">
                                  <p>This shows the stations-related wastage data.</p>
                                  <p>
                                    Add or remove stations from the charts by checking or unchecking
                                    the entries in the table below.
                                  </p>
                                  <p>
                                    Percentage change values are derived from comparing data of the
                                    previous date range
                                    <b>
                                      {getPreviousDateRangeString(
                                        selectedStartDate,
                                        selectedEndDate,
                                        selectedGroupBy
                                      )}
                                    </b>{' '}
                                    and the current selected date range
                                    <b>{` ${format(
                                      new Date(selectedStartDate),
                                      'dd-MM-yyyy'
                                    )} — ${format(new Date(selectedEndDate), 'dd-MM-yyyy')}`}</b>
                                    .
                                  </p>
                                </Typography>
                              }
                            >
                              <InfoIcon
                                onClick={() => this.onClickInfoIcon()}
                                className={classes.tooltipIcon}
                              />
                            </Tooltip>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                      <Grid item />
                      <Grid item>
                        <Button
                          className={classes.downloadButton}
                          variant={
                            arrStationServiceWasteAnalysisForTable.length === 0
                              ? 'outlined'
                              : 'contained'
                          }
                          disabled={arrStationServiceWasteAnalysisForTable.length === 0}
                          onClick={() => this.downloadTableData()}
                        >
                          <GetAppRoundedIcon />
                          <Typography variant="body4">Download Table</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Location dropdown and weight/cost toggle */}
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <DropdownList
                          label={isCompanyUser ? 'Location' : 'Service Group'}
                          backgroundColor="white"
                          selectedValue={isCompanyUser ? selectedLocationId : selectedServiceGroup}
                          arrDropdownItem={this.getArrDropdownLocation()}
                          onChange={(event) => this.onChangeDropdownList(event)}
                        />
                      </Grid>
                      <Grid item>
                        <ToggleWeightCostButton
                          selectedToggleValue={selectedToggleValueForWeightCost}
                          onChange={(event, newSelectedToggleValue) =>
                            this.onChangeToggleWeightCostButton(event, newSelectedToggleValue)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Waste Analysis by stations for Bar Chart */}
                  <Grid container>
                    <Grid
                      container
                      md={6}
                      sm={12}
                      direction="column"
                      wrap="nowrap"
                      className={classes.rootCardGridItemBarChart}
                    >
                      <Grid item className={classes.rootCardGridSubtitle}>
                        <Typography variant="h2" color="primary" align="center">
                          WASTAGE BY STATIONS
                        </Typography>
                      </Grid>
                      <Grid item>
                        <BarChart
                          xAxisData={xAxisDataForBarChart}
                          yAxisData={yAxisDataForBarChart}
                          yAxisLabel={
                            selectedToggleValueForWeightCost === 'weight'
                              ? 'WEIGHT (KG)'
                              : `COST (${currency})`
                          }
                          labelData={labelDataForBarChart}
                          isStationBreakdown
                        />
                      </Grid>
                    </Grid>

                    {/* Waste Analysis by stations for Line Chart */}
                    <Grid
                      container
                      md={6}
                      sm={12}
                      direction="column"
                      wrap="nowrap"
                      className={classes.rootCardGridItemLineChart}
                    >
                      <Grid item className={classes.rootCardGridSubtitle}>
                        <Typography variant="h2" color="primary" align="center">
                          STATION WASTAGE BY {this.getGroupByText()}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LineChart
                          xAxisData={xAxisDataForLineChart}
                          yAxisData={yAxisDataForLineChart}
                          yAxisLabel={
                            selectedToggleValueForWeightCost === 'weight'
                              ? 'WEIGHT (KG)'
                              : `COST (${currency})`
                          }
                          labelData={labelDataForLineChart}
                          isInStationBreakdown
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Waste Analysis by station and service for Table */}
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <StationsBreakdownTable
                      arrStationServiceWasteAnalysis={
                        arrStationServiceWasteAnalysisForTableToBeDisplayed
                      }
                      toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      arrSelectedStationServiceWasteAnalysis={
                        arrSelectedStationServiceWasteAnalysis
                      }
                      updateGraphAndTableForArrSelectedStationServiceWasteAnalysis={(
                        arrUpdatedSelectedStationServiceWasteAnalysis,
                        isFilter
                      ) =>
                        this.updateGraphAndTableForArrSelectedStationServiceWasteAnalysis(
                          arrUpdatedSelectedStationServiceWasteAnalysis,
                          isFilter
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

StationsBreakdown.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(StationsBreakdown)));
