import React, { PureComponent } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { withStyles } from '@material-ui/core/styles';

import { AppContext, CONSTANT } from '../../AppContext';

const DAYS_OF_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const styles = () => ({
  calendar: {
    'box-shadow': '0 0 0px',
    fontWeight: '600',
  },
});

const DATE_RANGE_LIMIT = {
  [CONSTANT.groupByDay]: 7 * 8, // 7 days * 8 weeks
  [CONSTANT.groupByWeek]: 7 * 14, // 7 days * 14 weeks
};

class DatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      minDate: undefined,
      maxDate: undefined,
    };
  }

  componentDidMount() {
    this.updateMinMaxDate();
  }

  componentDidUpdate(prevProps) {
    const { arrCalendarRange: arrPreviousCalenderRange, groupBy: previousGroupBy } = prevProps;
    const { arrCalendarRange: arrUpdatedCalenderRange, groupBy: updatedGroupBy } = this.props;
    if (
      arrPreviousCalenderRange.length !== arrUpdatedCalenderRange.length ||
      previousGroupBy !== updatedGroupBy
    ) {
      this.updateMinMaxDate();
    }
  }

  /**
   * This function gets the minimum and maximum date to be displayed on datepicker as per grouping chosen
   * If group by day, minimum and maximum date can only be 5 weeks prior or later than the 1st chosen date
   * If group by week, minimum and maximum date that can be chosen is 8 weeks prior or later than the 1st chosen date
   * If group by month, minimum date is unbound and maximum date that can be chosen is the last date of
   * the previous month
   */
  updateMinMaxDate() {
    const { lastDayLuxonToDisplayWasteAnalysis } = this.context;
    const { arrCalendarRange, groupBy } = this.props;
    const lastDayDateToDisplayWasteAnalysis = new DateObject(
      lastDayLuxonToDisplayWasteAnalysis.toFormat('yyyy-MM-dd')
    );
    let minDate;
    let maxDate;
    // Group by month does not have a date range limit
    if (groupBy === CONSTANT.groupByMonth) {
      maxDate =
        lastDayLuxonToDisplayWasteAnalysis.endOf('month').toFormat('yyyy-MM-dd') ===
        lastDayLuxonToDisplayWasteAnalysis.toFormat('yyyy-MM-dd')
          ? lastDayDateToDisplayWasteAnalysis
          : lastDayDateToDisplayWasteAnalysis.subtract(1, 'month').toLastOfMonth();
    }
    // If 2 dates have already been selected for date range, only set the maxDate
    else if (arrCalendarRange.length === 2) {
      if (groupBy === CONSTANT.groupByDay) {
        maxDate = lastDayDateToDisplayWasteAnalysis;
      } else if (groupBy === CONSTANT.groupByWeek) {
        maxDate = lastDayDateToDisplayWasteAnalysis.subtract(
          lastDayDateToDisplayWasteAnalysis.weekDay.index,
          'days'
        );
      }
    }
    // If only 1 date has been selected for date range, bound the user to the date range limit set, where
    // max date cannot exceed current group by's maximum allowable date
    else if (arrCalendarRange.length === 1) {
      const dateRangeLimit = DATE_RANGE_LIMIT[groupBy];
      const dateSelected = arrCalendarRange[0];
      minDate = new DateObject(dateSelected);
      maxDate = new DateObject(dateSelected);
      if (groupBy === CONSTANT.groupByDay) {
        minDate = minDate.subtract(dateRangeLimit - 1, 'days');
        const maxAllowableDate = lastDayDateToDisplayWasteAnalysis;
        const maxDateLimit = maxDate.add(dateRangeLimit - 1, 'days');
        maxDate = maxDateLimit < maxAllowableDate ? maxDateLimit : maxAllowableDate;
      } else if (groupBy === CONSTANT.groupByWeek) {
        const weekDayOffset = dateSelected.weekDay.index === 0 ? 6 : dateSelected.weekDay.index - 1;
        minDate = minDate.subtract(weekDayOffset - 7 + dateRangeLimit, 'days');
        const maxAllowableDate = lastDayDateToDisplayWasteAnalysis.subtract(
          lastDayDateToDisplayWasteAnalysis.weekDay.index,
          'days'
        );
        const maxDateLimit = maxDate.add(dateRangeLimit - maxDate.weekDay.index, 'days');
        maxDate = maxDateLimit < maxAllowableDate ? maxDateLimit : maxAllowableDate;
      }
    }
    this.setState({ minDate, maxDate });
  }

  render() {
    const { classes, updateCalendar, groupBy, calendarRef, arrCalendarRange } = this.props;
    const { minDate, maxDate } = this.state;

    return (
      <Calendar
        range
        rangeHover
        onlyMonthPicker={groupBy === 'groupByMonth'}
        showOtherDays
        weekStartDayIndex={1}
        value={arrCalendarRange}
        onChange={(newArrCalendarRange) => updateCalendar(newArrCalendarRange)}
        minDate={minDate}
        maxDate={maxDate}
        weekDays={DAYS_OF_WEEK}
        ref={calendarRef}
        className={`rmdp-calendar ${classes.calendar}`}
      />
    );
  }
}
DatePicker.contextType = AppContext;
export default withStyles(styles)(DatePicker);
